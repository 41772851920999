import * as React from 'react';
import { graphql } from 'gatsby';

import LayoutConsumer from '../components/layout-context';
import Seo from '../components/seo';
import FancyTitle from '../components/fancy-title';
import Positions from '../components/positions';

function JobDetail(data) {
  const [job, setJob] = React.useState(data.data.personioPosition);
  let { metaTitle, metaDescription } = data.pageContext;

  /* remove inline-styling from job-descriptions */
  React.useEffect(() => {
    if (job && job?.jobDescriptions) {
      const _jobDescriptions = job.jobDescriptions.map((description) => {
        const _description = description;
        const element = document.createElement('div');
        element.innerHTML = description.value;
        element.querySelectorAll('*').forEach((node) => {
          node.removeAttribute('style');
        });
        _description.value = element.innerHTML;
        return _description;
      });
      const _job = { ...job, jobDescriptions: _jobDescriptions };
      if (JSON.stringify(job) !== JSON.stringify(_job)) {
        setJob(_job);
      }
    }
  }, [job]);

  const TAGS = {
    permanent: 'Festanstellung',
    'full-time': 'Vollzeit',
  };

  const EVENTS = {
    746783: 12128209,
    729046: 12128257,
    847572: 12969361,
    1169697: 14603289,
  };
  if (metaTitle === 'Projektmanager (m/w/d)') {
    metaTitle = 'Projektmanager (m/w/d) für Shopify plus Agentur';
    metaDescription = 'Leite Projekte in unserer Shopify Plus Agentur in Deutschland! Unterstütze Kunden bei ✓ Shop Einrichtung ✓ Migration ✓ uvm.';
  }
  return (
    <LayoutConsumer>
      {({ set }) => {
        set({ pageTitle: metaTitle, fullWidth: true });
        return (
          <>
            <Seo title={metaTitle} description={metaDescription} />
            <div className="px-4 lg:px-16 flex flex-col pb-[100px] pt-20 lg:py-36 lg:pb-36 job-detail">
              <div className="flex gap-4 mb-8 text-white">
                {job.employmentType && (
                  <div className="bg-green-900 p-2 text-xs lg:text-base">
                    {TAGS[job.employmentType]}
                  </div>
                )}
                {job.schedule && (
                  <div className="bg-green-900 p-2 text-xs lg:text-base">
                    {TAGS[job.schedule]}
                  </div>
                )}
              </div>
              <FancyTitle
                text={job.name}
                className="text-left"
                tag="h1"
              />
              <p>
                Wir sind eine auf das E-Commerce System Shopify spezialisierte
                Agentur. Unser Fokus liegt hauptsächlich auf den größeren
                Projekten mit dem Enterprise Paket Shopify Plus.
                <br />
                Die Latori GmbH wurde 2012 gegründet, ist inhabergeführt und
                besteht momentan aus ca. 35 Mitarbeitern in verschiedenen
                Bereichen. Wir haben 2 Standorte: Wuppertal und München - ein
                größerer Teil unserer Entwickler arbeitet auch zu 100% remote.
              </p>
              {job.jobDescriptions.map((description) => (
                <div className="mt-[60px] lg:mt-[100px]" key={`job-description-${description.name}`}>
                  <FancyTitle
                    text={description.name}
                    tag="h3"
                    fontSize="lg:text-4xl"
                    marginBottom="mb-8"
                  />
                  <div
                    dangerouslySetInnerHTML={{ __html: description.value }}
                  />
                </div>
              ))}
              <a
                href={`https://latori.jobs.personio.de/job/${job.positionId}#apply`}
                target="_blank"
                rel="noreferrer"
                className="text-latori-green font-monospace text-center lg:text-left uppercase text-xl mt-8 lg:mt-16 w-fit"
                onClick={() => EVENTS[job.positionId] && window && window.lintrk && window.lintrk('track', { conversion_id: EVENTS[job.positionId] })}
              >
                Jetzt bewerben
                {' '}
                <span>→</span>
              </a>
              <Positions
                title="Weitere offene Stellen"
                currentPositionId={job.positionId}
              />
            </div>
          </>
        );
      }}
    </LayoutConsumer>
  );
}

export default JobDetail;

export const jobDetailQuery = graphql`
  query JobDetailQuery($id: String!) {
    personioPosition (id: { eq: $id }) {
      positionId
      name
      employmentType
      seniority
      schedule
      yearsOfExperience
      department {
        name
      }
      jobDescriptions {
        name
        value
      }
    }
  }
`;
